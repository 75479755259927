
import PhoneExtensionHistory from '@/components/employee/PhoneExtensionsHistory.vue'
import MailAddressesHistory from '@/components/employee/MailAddressesHistory.vue'
import { CampaignService, EmployeeService } from '@/services'
import { Campaign, Employee, EmployeeCampaignAssignment, EmployeeMailAddress } from '@/types'
import { Options, Vue } from 'vue-class-component'
import { FmtUtils } from '@/services'
import { Modal } from 'bootstrap'
import MessagesComponent from '@/components/Messages.vue'
import Spinner from '@/components/Spinner.vue'
import FormInputPersonalIdentityNumber from '@/components/FormInputPersonalIdentityNumber.vue'
import { ModalUtils } from '@/services/modalUtils'

@Options({
  name: 'ShowEmployee',
  components: {
    PhoneExtensionHistory,
    MailAddressesHistory,
    FormInputPersonalIdentityNumber,
    Spinner,
  },
})
export default class ShowEmployeeView extends Vue {
  loadingEmployee = false
  employee: Employee | null = null

  loadingCampaigns = false
  campaigns: Campaign[] | null = null

  formData = {
    personalIdentityNumber: '',
    phoneExtension: null as number | null,

    addCampaign: null as Campaign | null,
    removeCampaignAssignment: null as EmployeeCampaignAssignment | null,

    addEmailAddress: '',
    removeMailAddress: null as EmployeeMailAddress | null,
    setPrimaryMailAddress: null as EmployeeMailAddress | null,
  }

  fmtUtils = FmtUtils

  setPhoneExtensionModal: Modal | null = null
  settingPhoneExtension = false

  editNameModal: Modal | null = null

  editPINModal: Modal | null = null

  addingCampaignAssignment = false
  addCampaignModal: Modal | null = null

  removingCampaignAssignment = false
  removeCampaignModal: Modal | null = null

  addingEmailAddress = false
  checkingEmailAvailability = false
  addEmailAddressModal: Modal | null = null

  removingMailAddress = false
  removeMailAddressModal: Modal | null = null

  settingPrimaryMailAddress = false
  setPrimaryMailAddressModal: Modal | null = null

  mounted(): void {
    const employeeNumber = parseInt(this.$route.params.employeeNumber as string)
    this.fetchEmployee(employeeNumber).then((employee) => {
      this.employee = employee ?? null
    })

    this.fetchCampaigns().then((campaigns) => {
      this.campaigns = campaigns
    })

    // Setup modals
    this.setPhoneExtensionModal = ModalUtils.NewModal('#setPhoneExtensionModal', () => {
      ;(this.$refs.phoneExtensionErrorHandler as MessagesComponent).clear()
      this.formData.phoneExtension = this.employee?.currentExtension?.extension ?? null
    })

    this.editNameModal = ModalUtils.NewModal('#editNameModal', () => {
      ;(this.$refs.editNameModalMessages as MessagesComponent).clear()
    })

    this.editPINModal = ModalUtils.NewModal('#editPINModal', () => {
      ;(this.$refs.editPINModalMessages as MessagesComponent).clear()
      this.formData.personalIdentityNumber = this.employee?.personalIdentityNumber ?? ''
    })

    this.addCampaignModal = ModalUtils.NewModal('#addCampaignModal', () => {
      this.formData.addCampaign = null
      ;(this.$refs.addCampaignModalMessages as MessagesComponent).clear()
    })
    this.removeCampaignModal = ModalUtils.NewModal('#removeCampaignModal', () => {
      this.formData.removeCampaignAssignment = null
      ;(this.$refs.removeCampaignModalMessages as MessagesComponent).clear()
    })

    this.addEmailAddressModal = ModalUtils.NewModal('#addEmailAddressModal', () => {
      this.formData.addEmailAddress = ''
      ;(this.$refs.addEmailAddressModalMessages as MessagesComponent).clear()
      const el = this.$refs.addEmailAddressModalCampaignInputRef as HTMLInputElement
      el.classList.remove('is-valid')
      el.classList.remove('is-invalid')
    })
    this.removeMailAddressModal = ModalUtils.NewModal('#removeMailAddressModal', () => {
      this.formData.removeMailAddress = null
      ;(this.$refs.removeMailAddressModalMessages as MessagesComponent).clear()
    })
    this.setPrimaryMailAddressModal = ModalUtils.NewModal('#setPrimaryMailAddressModal', () => {
      this.formData.setPrimaryMailAddress = null
      ;(this.$refs.setPrimaryMailAddressModalMessages as MessagesComponent).clear()
    })
  }

  async refresh(): Promise<void> {
    this.employee = null
    const employeeNumber = parseInt(this.$route.params.employeeNumber as string)
    this.fetchEmployee(employeeNumber).then((employee) => {
      this.employee = employee ?? null
    })
  }

  async fetchEmployee(employeeNumber: number): Promise<Employee | null> {
    try {
      this.loadingEmployee = true
      return await EmployeeService.fetchEmployee(employeeNumber)
    } catch (err: unknown) {
      ;(this.$refs.errorHandler as MessagesComponent).pushErr(err)
      return null
    } finally {
      this.loadingEmployee = false
    }
  }

  async fetchCampaigns(): Promise<Campaign[]> {
    try {
      this.loadingCampaigns = true
      return await CampaignService.fetchCampaigns()
    } catch (err: unknown) {
      ;(this.$refs.errorHandler as MessagesComponent).pushErr(err)
      return []
    } finally {
      this.loadingCampaigns = false
    }
  }

  // #region Phone extension
  async setPhoneExtension(): Promise<void> {
    const msgs = this.$refs.phoneExtensionErrorHandler as MessagesComponent
    msgs.clear()

    if (this.employee === null) {
      msgs.pushErr('Internt fel. Du måste ladda om sidan!')
      return
    }

    const newExtension = this.formData.phoneExtension
    if (!newExtension || isNaN(newExtension)) {
      msgs.pushErr('Inte en giltig inmatning')
      return
    }

    try {
      this.settingPhoneExtension = true
      await EmployeeService.setPhoneExtension(this.employee, newExtension)
      msgs.pushInfo('OK')
      setTimeout(async () => {
        this.setPhoneExtensionModal?.hide()
        await this.refresh()
      }, 1000)
    } catch (err) {
      msgs.pushErr(err)
      await this.refresh()
    } finally {
      this.settingPhoneExtension = false
    }
  }

  async clearPhoneExtension(): Promise<void> {
    const msgs = this.$refs.phoneExtensionErrorHandler as MessagesComponent
    msgs.clear()

    if (this.employee === null) {
      msgs.pushErr('Internt fel. Du måste ladda om sidan!')
      return
    }

    try {
      this.settingPhoneExtension = true
      await EmployeeService.clearPhoneExtension(this.employee)
      msgs.pushInfo('OK')
      setTimeout(async () => {
        this.setPhoneExtensionModal?.hide()
        await this.refresh()
      }, 1000)
    } catch (err) {
      msgs.pushErr(err)
    } finally {
      this.settingPhoneExtension = false
    }
  }
  // #endregion

  // #region Update employee
  async updateEmployeeName(evt: Event): Promise<void> {
    if (this.employee == null) {
      return
    }

    const msgs = this.$refs.editNameModalMessages as MessagesComponent
    msgs.clear()

    const form = evt.target as HTMLFormElement
    const firstNameInput = form.querySelector('#editNameModalFirstName') as HTMLInputElement
    const lastNameInput = form.querySelector('#editNameModalLastName') as HTMLInputElement

    const payload = new Employee({
      employeeNumber: this.employee.employeeNumber,
      firstName: firstNameInput.value,
      lastName: lastNameInput.value,
    })

    try {
      this.employee = await EmployeeService.updateEmployee(payload)
      msgs.pushInfo('OK')
      setTimeout(() => {
        this.editNameModal?.hide()
      }, 1000)
    } catch (err) {
      msgs.pushErr(err)
    }
  }

  async updateEmployeePIN(evt: Event): Promise<void> {
    console.log(evt)

    if (this.employee == null) {
      return
    }

    const msgs = this.$refs.editPINModalMessages as MessagesComponent
    msgs.clear()

    const form = evt.target as HTMLFormElement
    const pinInput = form.querySelector('#editPINModalPIN') as HTMLInputElement

    const payload = new Employee({
      employeeNumber: this.employee.employeeNumber,
      personalIdentityNumber: pinInput.value.replace(/[^0-9]/g, ''),
    })

    try {
      this.employee = await EmployeeService.updateEmployee(payload)
      msgs.pushInfo('OK')
      setTimeout(() => {
        this.editPINModal?.hide()
      }, 1000)
    } catch (err) {
      msgs.pushErr(err)
    }
  }
  // #endregion

  // #region Campaign assignments
  async addCampaignAssignment(): Promise<void> {
    const msgs = this.$refs.addCampaignModalMessages as MessagesComponent
    msgs.clear()

    if (!this.employee) {
      msgs.pushErr('Internt fel. Ladda om sidan!')
      return
    } else if (!this.formData.addCampaign) {
      msgs.pushErr('Välj kampanj!')
      return
    }

    try {
      this.addingCampaignAssignment = true
      this.employee = await EmployeeService.addCampaignAssignmen(this.employee, this.formData.addCampaign)
      msgs.pushInfo('OK')
      setTimeout(() => {
        this.addCampaignModal?.hide()
      }, 1000)
    } catch (err) {
      msgs.pushErr(err)
    } finally {
      this.addingCampaignAssignment = false
    }
  }

  async removeCampaignAssignment(): Promise<void> {
    const msgs = this.$refs.removeCampaignModalMessages as MessagesComponent
    msgs.clear()

    if (!this.employee) {
      msgs.pushErr('Internt fel. Ladda om sidan!')
      return
    } else if (!this.formData.removeCampaignAssignment) {
      msgs.pushErr('Välj kampanj!')
      return
    }

    try {
      this.removingCampaignAssignment = true
      this.employee = await EmployeeService.removeCampaignAssignmen(
        this.employee,
        this.formData.removeCampaignAssignment
      )
      msgs.pushInfo('OK')
      setTimeout(() => {
        this.removeCampaignModal?.hide()
      }, 1000)
    } catch (err) {
      msgs.pushErr(err)
    } finally {
      this.removingCampaignAssignment = false
    }
  }
  // #endregion

  // #region E-mail address
  async checkEmailAvailable(emailAddress: string | undefined, elem: unknown): Promise<void> {
    const msgs = this.$refs.addEmailAddressModalMessages as MessagesComponent
    msgs.clear()

    if (!emailAddress) {
      return
    }

    const el = elem as HTMLInputElement
    el.classList.remove('is-valid')
    el.classList.remove('is-invalid')

    try {
      this.checkingEmailAvailability = true
      const availability = await EmployeeService.isEmailAddressAvailable(emailAddress)

      if (availability.isAvailable) {
        el.classList.add('is-valid')
      } else {
        el.classList.add('is-invalid')
      }
    } catch (err: unknown) {
      el.classList.add('is-invalid')
      msgs.pushErr(err)
    } finally {
      this.checkingEmailAvailability = false
    }
  }

  async addEmailAddress(): Promise<void> {
    const msgs = this.$refs.addEmailAddressModalMessages as MessagesComponent
    msgs.clear()

    if (!this.employee) {
      msgs.pushErr('Internt fel. Ladda om sidan!')
      return
    } else if (this.formData.addEmailAddress.trim() === '') {
      msgs.pushErr('Fyll i e-postadress!')
      return
    }

    try {
      this.addingEmailAddress = true
      this.employee = await EmployeeService.addEmailAddress(this.employee, this.formData.addEmailAddress.trim())
      msgs.pushInfo('OK')
      setTimeout(() => {
        this.addEmailAddressModal?.hide()
      }, 1000)
    } catch (err) {
      msgs.pushErr(err)
    } finally {
      this.addingEmailAddress = false
    }
  }

  async removeMailAddress(): Promise<void> {
    const msgs = this.$refs.removeMailAddressModalMessages as MessagesComponent
    msgs.clear()

    if (!this.employee) {
      msgs.pushErr('Internt fel. Ladda om sidan!')
      return
    } else if (!this.formData.removeMailAddress) {
      msgs.pushErr('Välj e-postadress först!')
      return
    }

    try {
      this.removingMailAddress = true
      this.employee = await EmployeeService.removeMailAddress(this.employee, this.formData.removeMailAddress)
      msgs.pushInfo('OK')
      setTimeout(() => {
        this.removeMailAddressModal?.hide()
      }, 1000)
    } catch (err) {
      msgs.pushErr(err)
    } finally {
      this.removingMailAddress = false
    }
  }

  async setPrimaryMailAddress(): Promise<void> {
    const msgs = this.$refs.setPrimaryMailAddressModalMessages as MessagesComponent
    msgs.clear()

    if (!this.employee) {
      msgs.pushErr('Internt fel. Ladda om sidan!')
      return
    } else if (!this.formData.setPrimaryMailAddress) {
      msgs.pushErr('Välj e-postadress först!')
      return
    }

    try {
      this.settingPrimaryMailAddress = true
      this.employee = await EmployeeService.setPrimaryMailAddress(this.employee, this.formData.setPrimaryMailAddress)
      msgs.pushInfo('OK')
      setTimeout(() => {
        this.setPrimaryMailAddressModal?.hide()
      }, 1000)
    } catch (err) {
      msgs.pushErr(err)
    } finally {
      this.settingPrimaryMailAddress = false
    }
  } // #endregion
}
