
import { defineComponent } from 'vue'
import { Employee } from '@/types'
import { FmtUtils } from '@/services'

export default defineComponent({
  name: 'PhoneExtensionsHistoryComponent',
  props: {
    employee: Employee,
  },
  data: function () {
    return {
      fmtUtils: FmtUtils,
    }
  },
})
